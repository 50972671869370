import $ from "jquery";
// Import Highway
import Highway from '@dogstudio/highway';

// GSAP Library
import Tween from 'gsap';
//import {itemsSlideRight,animInit,animMenu,animFooter,animServices,animPres,animProductsHeader,animProducts,animCustomer,researchInit,animNews, tlFooter} from './animations.js';
// Fade
class SlideTransition extends Highway.Transition {
  in({ from, to, done }) {
    if(location.href.indexOf('index') === -1) {
      $('body').removeClass('animated');
    }
    
    // Reset Scroll
    window.scrollTo(0, 0);
    // Remove Old View

    var fromSlug = $(from)[0].dataset.routerView;
    var toSlug = $(to)[0].dataset.routerView;

    if(fromSlug.search('electronic') !== -1 && toSlug.search('passive') !== -1){  
      //$('.main_header').css({ backgroundColor: '#f0f0f0' });
    }
    if(fromSlug.search('passive') !== -1 && toSlug.search('electronic') !== -1) { 
    }

    from.remove();


    $(to).css('position', 'relative');

    // Animation
    Tween.fromTo(to, 0.5,
      { "left": '-1000px', opacity: 0 },
      { 
        "left": '0', opacity: 1,
        onComplete: done
      }
    );
  }

  out({ from, done }) {
    
    window.scrollTo(0, 0);
    $('body').addClass('animated');
    //tlFooter.reverse();
    // Animation
    Tween.fromTo(from, 0.5,
      { "left": '0', opacity: 1 },
      { 
        "left": '1000px', opacity: 0,
        onComplete: done
      }
    );
  }
}

export default SlideTransition;