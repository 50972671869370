import '../../jquery-global.js';
import Highway from '@dogstudio/highway';
import { TimelineMax } from "gsap/all";

class DefaultRenderer extends Highway.Renderer {


    // On enter on page
    onEnter() {
        // Ajout d'une class spécifique à chaque page
        $('body').addClass('section-'+this.properties.slug);
        
        // Ajout d'une classe selon qu'on soit en electronic ou passive
        if (location.href.indexOf('passive') !== -1) {
            if(!$('body').hasClass('section-passive')) $('body').addClass('section-passive');
            $('body').removeClass('section-electronic');
        } else if (location.href.indexOf('electronic') !== -1) {
            if(!$('body').hasClass('section-passive')) $('body').addClass('section-electronic');
            $('body').removeClass('section-passive');
        } else {
            $('body').removeClass('section-electronic');
            $('body').removeClass('section-passive');
            $('body').addClass('section-passive');
        }

        self = this;
        $('a.close-menu').click(function(){
            self.closeMobileMenus();
        });

        // Timeline footer
        this.tlFooter = new TimelineMax({
            paused: true, delay: 0
        });

        this.animateMenu();
        this.animateFooter();
        this.handleScroll();
        this.openMobile();

        if (typeof this.onDoneEnter !== "undefined") {
            this.onDoneEnter();
        }
    }

    openMobile() {
        console.log('openmobile');
        // $('.mobile-hamburger-electronic').click(function() {
        //     console.log('openmobile click');
        //     if(!$('#mobile-nav-electronic').hasClass('open')) {
                
        //         $('#mobile-nav-electronic').animate({'left': '-0px'}, function(){ $('#mobile-nav-electronic').show(); $('#mobile-nav-electronic').addClass('open'); });
        //     } else {
        //         $('#mobile-nav-electronic').animate({'left': '-300px'}, function(){ $('#mobile-nav-electronic').hide(); $('#mobile-nav-electronic').removeClass('open'); });
        //     }
            
        // });

        
        // $('.mobile-hamburger-passive').click(function() {
        //     if(!$('#mobile-nav-passive').hasClass('open')) {
                
        //         $('#mobile-nav-passive').animate({'left': '-0px'}, function(){ $('#mobile-nav-passive').show(); $('#mobile-nav-passive').addClass('open'); });
        //     } else {
        //         $('#mobile-nav-passive').animate({'left': '-300px'}, function(){ $('#mobile-nav-passive').hide(); $('#mobile-nav-passive').removeClass('open');  });
        //     }
            
        // });
    }

    // Fonction pour le masquage du menu selon le scroll
    handleScroll() {
        var lastScrollTop = 0;
        $(window).scroll(function(event){
            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                var scrollTop = $(window).scrollTop();
                if(scrollTop > 105) {
                    if(!$('.main_header').is(':animated')) $('.main_header').animate({ opacity: 0, top: '-105px' }, 500);
                }
            } else {
                if(!$('.main_header').is(':animated')) $('.main_header').animate({ opacity: 1, top: '-0' }, 500);
            }
            if(st == 0) {
                $('.main_header').animate({ opacity: 1, top: '-0' }, 500);
            }
            lastScrollTop = st;
        });
    }

    animateMenu(yMenu = 0, withHeader = true) {
        // Anime le menu
        var main_nav = $('.main_nav, nav.passive');
        var main_header = $('.main_header');


        this.tlNav = new TimelineMax({
            paused: true, delay: 0
        });

        if ($('.main_nav').length > 0) {
            if(withHeader) this.tlNav.to(main_header, 0.5, { y: yMenu, autoAlpha: 1 }, 0.2, "initHome");
            this.tlNav.to(main_nav, 0.5, { y: yMenu, autoAlpha: 1 }, 0.2, "initHome");
        } 

        this.tlNav.play();
    }

    // Footer
    animateFooter() {


        var foot = $('.footer-accueil .container');
        self = this;

        var tlFooter = this.tlFooter
            .to(foot, 1, { y: 0, autoAlpha: 1 }, 0.1, "initFoot");

        //alert('animfooter');
        $('.footer-accueil').each(function () {
            if ($(this).isInViewportFoot() || self.properties.slug == "customer") {
                tlFooter.play();
            } else {
                tlFooter.reverse();
            }
        });

        $(window).on('resize scroll', function () {
            $('.footer-accueil').each(function () {
                if ($(this).isInViewportFoot()  || self.properties.slug == "customer") {
                    tlFooter.play();
                } else {
                    tlFooter.reverse();
                }
            });
        });
    }

    onLeave() {

        //accueil-electronic

        $('body').removeClass('section-'+this.properties.slug);
        
        this.closeMobileMenus();

        // Il faut cacher le menu quand on part vers certaines pages
        if (location.href.indexOf('index') !== -1)   this.animateMenu(-105);
        if (location.href.indexOf('passive') !== -1 && this.properties.slug.indexOf('passive') === -1)  this.animateMenu(-105, false);
        if (location.href.indexOf('electronic') !== -1 && this.properties.slug.indexOf('electronic') === -1) this.animateMenu(-105, false);

        if (typeof this.onDoneLeave !== "undefined") {
            this.onDoneLeave();
        }
    }

    onEnterCompleted() {
        if (typeof this.onDoneEnterCompleted !== "undefined") {
            this.onDoneEnterCompleted();
        }

        this.initPanels();
    }


    initPanels() {
        
        $('document').ready(function () {
            $('.fakelink').click(function (e) {
                H.navigate(e);
            });
        });

        // Si on se trouve sur l'index et que les panels sont ouverts, il faut les refermer
        if (this.properties.slug == "index") {
            this.closePanels();
            
            this.closeMobileMenus();
        }

        // Les panels ne doivent être initialisés qu'une seule fois pour ne pas déclancher les events plus d'une fois
        if (!window.panelInit) {

            self = this;
            // Event du click sur le panel electronic
            $('#electronic-panel').on('click', function (e) {
                // On effectue l'animation que si les panels sont fermés
                if (!window.panelsopen) {
                    /*$('#electronic-panel').animate({ width: '100%', left: 0 }, 900).addClass('redress');
                    $('#electronic-panel').children('.inner').first().animate({ marginLeft: 0 }, 900).addClass('redress');
                    $('#passive-panel').animate({ width: '0%' }, 1500, null, function(){
                        $('#f-electronic-panel').click();
                    });*/
                    
                    self.closeMobileMenus();
                    $('#electronic-panel').addClass('open');
                    $('#passive-panel').addClass('closed');
                    $('#f-electronic-panel').click();
                    window.panelsopen = true;
                }
                return false;
            });

            // Event du click sur le panel electronic
            $('#passive-panel').on('click', function (e) {
                // On effectue l'animation que si les panels sont fermés
                if (!window.panelsopen) {
                   /* $('#passive-panel').animate({ width: '100%', right: 0 }, 900).addClass('redress');
                    $('#passive-panel').children('.inner').first().animate({ marginLeft: 0 }, 900).addClass('redress');
                    $('#electronic-panel').animate({ width: '0%' }, 1500, null, function(){
                        $('#f-passive-panel').click();
                    });*/
                    self.closeMobileMenus();
                   $('#passive-panel').addClass('open');
                    $('#electronic-panel').addClass('closed');
                    $('#f-passive-panel').click();


                    window.panelsopen = true;
                }
                return false;
            });

            window.panelInit = true;
        }

        // Les panels sont ouverts par defaut sur l'accueil electronic
        if (this.properties.slug == "accueil-electronic") {
            $('#electronic-panel').removeClass('closed');
            $('#electronic-panel').addClass('open');
            $('#passive-panel').addClass('closed');
            $('#passive-panel').removeClass('open');
        }
        // Les panels sont ouverts par defaut sur l'accueil electronic
        if (this.properties.slug == "passive-products") {
            $('#electronic-panel').removeClass('open');
            $('#electronic-panel').addClass('closed');
            $('#passive-panel').addClass('open');
            $('#passive-panel').removeClass('closed');
        }

    }

    closePanels() {
        $('#electronic-panel').removeClass('closed');
        $('#electronic-panel').removeClass('open');
        $('#passive-panel').removeClass('closed');
        $('#passive-panel').removeClass('open');
        
        this.closeMobileMenus();
        window.panelsopen = false;
    }

    closeMobileMenus() {

            if($('#mobile-nav-electronic').hasClass('open')) {
                $('#mobile-nav-electronic').animate({'left': '-300px'}, function(){ $('#mobile-nav-electronic').hide(); $('#mobile-nav-electronic').removeClass('open'); });
            }
            

        
            if($('#mobile-nav-passive').hasClass('open')) {
                $('#mobile-nav-passive').animate({'left': '-300px'}, function(){ $('#mobile-nav-passive').hide(); $('#mobile-nav-passive').removeClass('open');  });
            }
            


    }
    
}

// Don`t forget to export your renderer
export default DefaultRenderer;