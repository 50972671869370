import "../../../jquery-global.js";
import DefaultRenderer from "../default-renderer.js";
import { TimelineMax } from "gsap/all";

class ElectronicNextRenderer extends DefaultRenderer {
  // On enter on page
  onDoneEnter() {
    console.log("ElectronicNextRenderer onDoneEnter");

    var self = this;
  }

  onDoneEnterCompleted() {
    console.log("ElectronicNextRenderer onDoneEnterCompleted");
    $(".carousel-next").slick({
      nextArrow:
        '<div class="slick-next slick-arrow"><img src="/electronic/img/next.svg" alt="" /></div>',
      prevArrow:
        '<div class="slick-prev slick-arrow"><img src="/electronic/img/prev.svg" alt="" /></div>',
    });
  }

  // onDoneLeave() {
  //   if (location.href.indexOf("index") === -1)
  //     $("body").removeClass("haspanels");

  //   // $(".carousel-home").slick("unslick");
  //   console.log("ElectronicNextRenderer onDoneLeave");
  // }

  // onLeave() { [...] }
  // onEnterCompleted() { [...] }
  // onLeaveCompleted() { [...] }
}

// Don`t forget to export your renderer
export default ElectronicNextRenderer;
