import $ from "jquery";
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class ElectronicContactRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('ElectronicContactRenderer onDoneEnter');

        this.animateHeader();
        this.animateContacts();
    }

    animateHeader() {
        var tlHeader = new TimelineMax({
            paused: true, delay: 0
        });

        var header = $('.header-contact img');
        var pageTitle = $('.contact h1');
        tlHeader.to(header,1 , { y: 0, opacity: 1 }, 0.5, "initContact");
        tlHeader.to(pageTitle,1 , { y: 0, opacity: 1 }, 0.5, "initContact");
        tlHeader.play();
    }

    animateContacts() {
        var contactItem = $('.contact-item'),
        tlContact = new TimelineMax({
            paused: true, delay: 0
        });

        tlContact
            .staggerTo(contactItem, 1, { y: 0, opacity: 1 }, 0.2, "initContact+=1");
            tlContact.play();

    }
}


// Don`t forget to export your renderer
export default ElectronicContactRenderer;