import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

//Todo déplacer ceci quelque part où ca fonctionnera
window.$.fn.isInViewport = function(y) {
    var y = y || 1;
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var elementHeight = $(this).height();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    var deltaTop = Math.min(1, (elementBottom - viewportTop) / elementHeight);
    var deltaBottom = Math.min(1, (viewportBottom - elementTop) / elementHeight);
    //console.log($(this).attr('id'), deltaTop * deltaBottom >= y);
    return deltaTop * deltaBottom >= y;
};


window.$.fn.isInViewportFoot = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    
    return elementBottom > viewportTop && elementTop < viewportBottom;
};