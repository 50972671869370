import $ from "jquery";

require("slick-carousel");

$(document).ready(function () {
  var ww = $(window).width();
  $(".mobile-hamburger-electronic").click(function () {
    console.log("click hamb");
    if (!$("#mobile-nav-electronic").hasClass("open")) {
      $("#mobile-nav-electronic").show();
      $("#mobile-nav-electronic").addClass("open");
      $("#mobile-nav-electronic").animate({ left: "-0px" });
    } else {
      $("#mobile-nav-electronic").animate(
        { left: "-" + ww + "px" },
        function () {
          $("#mobile-nav-electronic").hide();
          $("#mobile-nav-electronic").removeClass("open");
        }
      );
    }
  });

  $(".mobile-hamburger-passive").click(function () {
    if (!$("#mobile-nav-passive").hasClass("open")) {
      $("#mobile-nav-passive").show();
      $("#mobile-nav-passive").addClass("open");
      $("#mobile-nav-passive").animate({ left: "-0px" });
    } else {
      $("#mobile-nav-passive").animate({ left: "-" + ww + "px" }, function () {
        $("#mobile-nav-passive").hide();
        $("#mobile-nav-passive").removeClass("open");
      });
    }
  });
  $(".onglets ul li").click(function () {
    $(".onglets ul li").removeClass("active");
    $(this).toggleClass("active");
  });

  $(".header-products-passive .header-content ul li").click(function () {
    $(".header-products-passive .header-content ul li").removeClass("active");
    $(this).toggleClass("active");
  });

  $(".link-piston").click(function () {
    $(".piston").show(300);
    $(".riderefine").hide(300);
  });

  $(".link-riderefine").click(function () {
    $(".riderefine").show(300);
    $(".piston").hide(300);
  });

  $(".js-scrollTo").on("click", function () {
    // Au clic sur un élément
    var page = $(this).attr("href"); // Page cible
    var speed = 750; // Durée de l'animation (en ms)
    $("html, body").animate({ scrollTop: $(page).offset().top }, speed); // Go
    return false;
  });

  var vid = document.getElementById("full-width-video");

  $("#full-width-video").click(function () {
    $(".button-video-rouge").toggle();
  });
  $(".button-video-rouge").click(function () {
    $(this).toggle();
    vid.play();
  });
});
