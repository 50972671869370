import $ from "jquery";
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class PassiveProductRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('PassiveProductRenderer onDoneEnter');
        this.animateVideos()
        this.initFancybox()
    }

    initFancybox() {
        $('document').ready(function(){
            $('.fancy').each(function(){
                $(this).attr('data-url', $(this).attr('href'));
                $(this).attr('href', '#');
            });
            $('.fancy').click(function(){
                $.fancybox.open({
                    src  : $(this).attr('data-url').replace(new RegExp('watch\\?v=', 'i'), 'embed/') + '?autoplay=1&amp;rel=0&amp;controls=1&amp;showinfo=0',
                    type : 'iframe'
                });
            });
        });
    }
    animateVideos() {
        var video_item = $('.video_item'),
            tlVideos = new TimelineMax({
                paused: true, delay: 0
            });

        tlVideos
            .staggerTo(video_item, 1, { y: 0, autoAlpha: 1 }, 0.1, "initVideos");

        tlVideos.play();
    }

}


// Don`t forget to export your renderer
export default PassiveProductRenderer;
