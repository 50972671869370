import '../../../jquery-global.js';
import '../../../parallax.js';
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class ElectronicAboutRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('ElectronicAboutRenderer onDoneEnter');
    }

    
    onDoneEnterCompleted() {
        $('.parallax-img').parallax();
    }

    onDoneLeave() {
        $('.parallax-mirror').remove();
    }
}


// Don`t forget to export your renderer
export default ElectronicAboutRenderer;