import "../../../jquery-global.js";
import DefaultRenderer from "../default-renderer.js";
import { TimelineMax } from "gsap/all";

class ElectronicAccueilRenderer extends DefaultRenderer {
  // On enter on page
  onDoneEnter() {
    console.log("ElectronicAccueilRenderer onDoneEnter");
    // this.animateHeader();
    $("body").addClass("haspanels");
    this.animatePres();
    this.animateServices();
  }

  onDoneEnterCompleted() {
    console.log("ElectronicAccueilRenderer onDoneEnterCompleted");
    $("body").removeClass("index");
    $(".carousel-home").slick({
      nextArrow:
        '<div class="slick-next slick-arrow"><img src="/electronic/img/next.svg" alt="" /></div>',
      prevArrow:
        '<div class="slick-prev slick-arrow"><img src="/electronic/img/prev.svg" alt="" /></div>',
    });
  }

  onDoneLeave() {
    if (location.href.indexOf("index") === -1)
      $("body").removeClass("haspanels");

    // $(".carousel-home").slick("unslick");
    console.log("ElectronicAccueilRenderer onDoneLeave");
  }

  // onLeave() { [...] }
  // onEnterCompleted() { [...] }
  // onLeaveCompleted() { [...] }

  animateHeader() {
    var logoheader = $(".header-accueil .logo-header");
    var slogan = $(".slogan");
    var headeraccueil = $(".header-accueil");

    this.tlNav.to(headeraccueil, 2, { autoAlpha: 1 }, 0.2, "initHome");
    this.tlNav.to(logoheader, 1, { y: 0, autoAlpha: 1 }, 0.3, "initHome");
    this.tlNav.to(slogan, 1, { y: 0, autoAlpha: 1 }, 0.5, "initHome");
  }

  animatePres() {
    // Homepage blocs coté - Anime le menu
    var welcomeimg = $(".welcome-img"),
      presentation_titre = $(".presentation_titre"),
      presentation = $(".presentation"),
      tlWelcome = new TimelineMax({
        paused: true,
        delay: 0,
      });

    tlWelcome
      .to(presentation_titre, 1, { x: 0, autoAlpha: 1 }, 0.2, "initWelcome")
      .to(presentation, 1, { x: 0, autoAlpha: 1 }, 0.1, "initWelcome")
      .staggerTo(
        welcomeimg,
        1,
        { x: 0, autoAlpha: 1 },
        0.3,
        "initWelcome-=0.9"
      );

    // Presentation
    $(".welcome").each(function () {
      var offsetsection = $(this).attr("data-offset");
      if ($(this).isInViewport(offsetsection)) {
        tlWelcome.play();
      }
    });
    $(window).on("resize scroll", function () {
      $(".welcome").each(function () {
        var offsetsection = $(this).attr("data-offset");
        if ($(this).isInViewport(offsetsection)) {
          tlWelcome.play();
        }
      });
    });
  }

  animateServices() {
    // Services - Anime les icones service
    var serv_img = $(".services-accueil .enteranimation img"),
      serv_title = $(".services-accueil .enteranimation h4"),
      serv_p = $(".services-accueil .enteranimation p"),
      serv_item = $(".serv_item"),
      tlServices = new TimelineMax({
        paused: true,
        delay: 0,
      });

    // tlServices
    //     .staggerTo(serv_img,1,{y: 0, autoAlpha:1}, 0.5,"initServicesHome")
    //     .staggerTo(serv_title,1,{y: 0, autoAlpha:1}, 0.7,"initServicesHome-=0.5")
    //     .staggerTo(serv_p,1,{y: 0, autoAlpha:1}, 0.9,"initServicesHome-=1")

    tlServices.staggerTo(
      serv_item,
      1,
      { y: 0, autoAlpha: 1 },
      0.5,
      "initServicesHome"
    );
    // Services
    $(".services-accueil .serv_item").each(function () {
      var offsetsection = $(this).attr("data-offset");
      if ($(this).isInViewport(offsetsection)) {
        tlServices.play();
        $(this).parent().addClass("is-visible");
      }
    });
    $(window).on("resize scroll", function () {
      $(".services-accueil .serv_item").each(function () {
        var offsetsection = $(this).attr("data-offset");
        if ($(this).isInViewport(offsetsection)) {
          tlServices.play();
          $(this).parent().addClass("is-visible");
        }
      });
    });
  }
}

// Don`t forget to export your renderer
export default ElectronicAccueilRenderer;
