// Import Highway
import $ from "jquery";
import Highway from "@dogstudio/highway";

// Import Transitions
import FadeTransition from "./transitions/fade-transition.js";

// Import Renderers
import DefaultRenderer from "./renderers/default-renderer.js";
import IndexRenderer from "./renderers/index-renderer.js";
import ElectronicAccueilRenderer from "./renderers/electronic/accueil-renderer.js";
import ElectronicCustomerRenderer from "./renderers/electronic/customer-renderer.js";
import ElectronicResearchRenderer from "./renderers/electronic/research-renderer.js";
import ElectronicNewsRenderer from "./renderers/electronic/news-renderer.js";
import ElectronicVideosRenderer from "./renderers/electronic/videos-renderer.js";
import ElectronicProductsRenderer from "./renderers/electronic/products-renderer.js";
import ElectronicAboutRenderer from "./renderers/electronic/about-renderer.js";
import PassiveProductsRenderer from "./renderers/passive/products-renderer.js";
import ElectronicContactRenderer from "./renderers/electronic/contact-renderer.js";
import ElectronicProductRenderer from "./renderers/electronic/product-renderer.js";
import SlideTransition from "./transitions/slide-transition.js";
import PassiveProductRenderer from "./renderers/passive/product-renderer.js";
import PassiveNewsRenderer from "./renderers/passive/news-renderer.js";
import ElectronicNextRenderer from "./renderers/electronic/next-renderer.js";

// Call Highway.Core once.
const H = new Highway.Core({
  renderers: {
    index: IndexRenderer,
    "accueil-electronic": ElectronicAccueilRenderer,
    products: ElectronicProductsRenderer,
    customer: ElectronicCustomerRenderer,
    research: ElectronicResearchRenderer,
    "news-electronic": ElectronicNewsRenderer,
    "news-passive": PassiveNewsRenderer,
    "videos-electronic": ElectronicVideosRenderer,
    "about-electronic": ElectronicAboutRenderer,
    "electronic-privacy": ElectronicCustomerRenderer,
    "electronic-next": ElectronicNextRenderer,
    "electronic-conditions": ElectronicCustomerRenderer,
    "passive-about": ElectronicAboutRenderer,
    "contact-electronic": ElectronicContactRenderer,
    "passive-contact": ElectronicContactRenderer,
    "passive-products": PassiveProductsRenderer,
    "products-cvsae": ElectronicProductRenderer,
    "products-cvsa2": ElectronicProductRenderer,
    "products-control-logic": ElectronicProductRenderer,
    "products-kinetic": ElectronicProductRenderer,
    "products-dualmode": ElectronicProductRenderer,
    "passive-product-bocs": PassiveProductRenderer,
    "passive-product-mcx": PassiveProductRenderer,
    "passive-product-mtv": PassiveProductRenderer,
    "passive-product-mtvcl": PassiveProductRenderer,
    "passive-product-rc": PassiveProductRenderer,
    "passive-product-rv": PassiveProductRenderer,
    "passive-product-ssd": PassiveProductRenderer,
  },
  transitions: {
    default: SlideTransition,
  },
});

window.H = H;

const body = document.body;
const path = window.location.pathname;

H.on("NAVIGATE_IN", ({ to, location }) => {
  // Test si on est sur la page d'accueil
  if (location.href.indexOf("index.html") !== -1) {
    if (!body.classList.contains("accueil")) body.classList.add("accueil");
  } else {
    body.classList.remove("accueil");
  }
});

// Listen the `NAVIGATE_END` event
H.on("NAVIGATE_END", ({ from, to, location }) => {
  // Analytics
  if (typeof gtag !== "undefined") {
    // eslint-disable-next-line
    gtag("config", "UA-77255120-39", {
      page_path: location.pathname,
      page_title: to.page.title,
      page_location: location.href,
    });
  }
});
