import $ from "jquery";
import Highway from '@dogstudio/highway';
import DefaultRenderer from './default-renderer.js';
import { TimelineMax } from "gsap/all";

class IndexRenderer extends DefaultRenderer {


    // On enter on page
    onEnter() { 
        $('body').addClass('accueil');
        $('body').addClass('index');
        $('body').addClass('haspanels');
        $('body').addClass('section-'+this.properties.slug);
        this.initPanels();
    }

    onLeave() {        
        $('body').removeClass('section-'+this.properties.slug);
        $('body').removeClass('accueil');
        $('body').removeClass('index');


    }
    // onLeave() { [...] }
    onEnterCompleted() {
        //this.detach(); $('#electronic-panel').
    }
    // onLeaveCompleted() { [...] }


}

// Don`t forget to export your renderer
export default IndexRenderer;