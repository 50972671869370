import $ from "jquery";
// Import Highway
import Highway from '@dogstudio/highway';

// GSAP Library
import Tween from 'gsap';
//import {itemsSlideRight,animInit,animMenu,animFooter,animServices,animPres,animProductsHeader,animProducts,animCustomer,researchInit,animNews, tlFooter} from './animations.js';
// Fade
class FadeTransition extends Highway.Transition {
  in({ from, to, done }) {
    if(location.href.indexOf('index') === -1) {
      $('body').removeClass('animated');
    }

    // animInit();
    // animMenu();
    // animPres();
    // animServices();
    // animFooter();
    // animProductsHeader();
    // animProducts();
    // animCustomer();
    // researchInit();
    // itemsSlideRight();
    // animNews();
    // Reset Scroll
    window.scrollTo(0, 0);
    // Remove Old View
    from.remove();

    // Animation
    Tween.fromTo(to, 0.5,
      { opacity: 0 },
      { 
        opacity: 1,
        onComplete: done
      }
    );
  }

  out({ from, done }) {
    
    window.scrollTo(0, 0);
    $('body').addClass('animated');
    //tlFooter.reverse();
    // Animation
    Tween.fromTo(from, 0.5,
      { opacity: 1 },
      {
        opacity: 0,
        onComplete: done
      }
    );
  }
}

export default FadeTransition;