import "../../../jquery-global.js";
import fancybox from "@fancyapps/fancybox";
import DefaultRenderer from "../default-renderer.js";
import { TimelineMax } from "gsap/all";

class ElectronicProductRenderer extends DefaultRenderer {
  // On enter on page
  onDoneEnter() {
    console.log("ElectronicProductRenderer onDoneEnter");
    self = this;
    this.animPage();
    this.initFancybox();
    $(window).on("resize scroll", () => {
      self.animPage();
    });
  }

  initFancybox() {
    $("document").ready(function () {
      $(".fancy").each(function () {
        $(this).attr("data-url", $(this).attr("href"));
        $(this).attr("href", "#");
      });
      $(".fancy").click(function () {
        $.fancybox.open({
          src:
            $(this)
              .attr("data-url")
              .replace(new RegExp("watch\\?v=", "i"), "embed/") +
            "?autoplay=1&amp;rel=0&amp;controls=1&amp;showinfo=0",
          type: "iframe",
        });
      });
    });
  }

  //Products
  animPage() {
    var productAnim = $(".product-anim");

    var tlItem = new TimelineMax({
      paused: true,
      delay: 0,
    });

    tlItem.staggerTo(
      productAnim,
      1,
      { y: 0, autoAlpha: 1 },
      0.2,
      "initProduct-=0.5"
    );

    $(".product-anim").each(function () {
      if ($(this).isInViewport() || 1) {
        tlItem.play();
      }
    });

    //console.log($('.product-anim').isInViewport());
  }
}

// Don`t forget to export your renderer
export default ElectronicProductRenderer;
