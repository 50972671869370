import $ from "jquery";
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class PassiveProductsRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('PassiveProductsRenderer onDoneEnter');
        this.animProducts();
        this.animProductsHeader();
        $('body').addClass('haspanels');
        this.initFancybox();
    }

    initFancybox() {
        $('document').ready(function(){
            $('.fancy').each(function(){
                $(this).attr('data-url', $(this).attr('href'));
                $(this).attr('href', '#');
            });
            $('.fancy').click(function(){
                $.fancybox.open({
                    src  : $(this).attr('data-url').replace(new RegExp('watch\\?v=', 'i'), 'embed/') + '?autoplay=1&amp;rel=0&amp;controls=1&amp;showinfo=0',
                    type : 'iframe'
                });
            });
        });
    }

    //Products
    animProductsHeader() {
        var
            headerProd_title = $('.header-products-passive h1'),
            headerProd_txt = $('.header-products-passive p'),
            headerProd_img = $('.header-products-passive .img-header'),
            onglets_items = $('.onglets .list-inline li'),

            tlHeaderProd = new TimelineMax({
                paused: true, delay: 0
            });

        tlHeaderProd
            .to(headerProd_title, 0.5, { y: 0, autoAlpha: 1 }, 0.8, "initHome")
            .to(headerProd_txt, 0.5, { y: 0, autoAlpha: 1 }, 1, "initHome")
            .to(headerProd_img, 1, { y: 0, autoAlpha: 1 }, 1.2, "initHome")
            .staggerTo(onglets_items, 1, { y: 0, autoAlpha: 1 }, 0.2, "initHome-=1.2")

        tlHeaderProd.play();

    }

    animProducts() {

        $('.product-tab-item').click(function () { $('.product-tab-item').removeClass('active'); $(this).toggleClass('active'); });

        $('.products-passive article').each(function () {
            var offsetsection = $(this).attr('data-offset'),
                Prod_title = $(this).find('h2'),
                Prod_txt = $(this).find('.brand-intro p'),
                Prod_link = $(this).find('.brand-link a'),
                Prod_video = $(this).find('.video-button a'),
                Prod_img = $(this).find('.brand-schema img'),
                tlProds = new TimelineMax({
                    paused: true, delay: 2
                });

            tlProds
                .to(Prod_title, 0.5, { y: 0, autoAlpha: 1 }, 0, "showProd")
                .to(Prod_txt, 0.5, { y: 0, autoAlpha: 1 }, 0.2, "showProd")
                .to(Prod_video, 0.5, { y: 0, autoAlpha: 1 }, 0.2, "showProd")
                .to(Prod_link, 0.5, { y: 0, autoAlpha: 1 }, 0.3, "showProd")
                .to(Prod_img, 0.5, { y: 0, autoAlpha: 1 }, 0.4, "showProd");

            if ($(this).isInViewport(offsetsection) || 1) {
                tlProds.play();
            }
        });

        //$('.prod_item').not(':first').hide(400, "swing");
        $('.product-tab-item').each(function () {
            $(this).click(function () {
                var tabId = $(this).attr('id');

                if (tabId == "all") {
                    $('.prod_item').each(function () {
                        $(this).show();
                    });
                } else {
                    $('#tab-' + tabId).show(400);
                    $('.products').not('#tab-' + tabId).each(function(){ $(this).hide(400); });
                }

            });

        });

        $(window).on('resize scroll', function () {
            $('.prod_item').each(function () {
                var offsetsection = $(this).attr('data-offset'),
                    Prod_title = $(this).find('h2'),
                    Prod_txt = $(this).find('.left-content p'),
                    Prod_link = $(this).find('.left-content a'),
                    Prod_img = $(this).find('.right-content img'),
                    tlProdsList = new TimelineMax({
                        paused: true, delay: 0
                    });

                tlProdsList
                    .to(Prod_title, 1, { y: 0, autoAlpha: 1 }, 0, "showProd")
                    .to(Prod_txt, 1, { y: 0, autoAlpha: 1 }, 0.2, "showProd")
                    .to(Prod_link, 1, { y: 0, autoAlpha: 1 }, 0.3, "showProd")
                    .to(Prod_img, 1, { y: 0, autoAlpha: 1 }, 0.4, "showProd")

                if ($(this).isInViewport(offsetsection) || 1) {
                    tlProdsList.play();
                }
            });
        });

    }

    onDoneLeave() {
        if(location.href.indexOf('index') === -1 && location.href.indexOf('electronic/pages/accueil') === -1) $('body').removeClass('haspanels');
    }

}


// Don`t forget to export your renderer
export default PassiveProductsRenderer;
