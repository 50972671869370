import $ from "jquery";
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class ElectronicProductsRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('ElectronicProductsRenderer onDoneEnter');
        this.animProducts();
        this.animProductsHeader();
    }


    //Products
    animProductsHeader() {
        var
            headerProd_title = $('.header-product-content h1'),
            headerProd_txt = $('.header-product-content p'),
            headerProd_img = $('.header-product .img-header'),
            onglets_items = $('.onglets li'),

            tlHeaderProd = new TimelineMax({
                paused: true, delay: 0
            });

        tlHeaderProd
            .to(headerProd_title, 0.5, { y: 0, autoAlpha: 1 }, 0.8, "initHome")
            .to(headerProd_txt, 0.5, { y: 0, autoAlpha: 1 }, 1, "initHome")
            .to(headerProd_img, 1, { y: 0, autoAlpha: 1 }, 1.2, "initHome")
            .staggerTo(onglets_items, 1, { y: 0, autoAlpha: 1 }, 0.2, "initHome-=1.2")

        tlHeaderProd.play();

    }

    animProducts() {

        $('.product-tab-item').click(function () { $('.product-tab-item').removeClass('active'); $(this).toggleClass('active'); });

        $('.prod_item').each(function () {
            var offsetsection = $(this).attr('data-offset'),
                Prod_title = $(this).find('h2'),
                Prod_txt = $(this).find('.left-content p, .brand-intro p'),
                Prod_link = $(this).find('.left-content a, .brand-link a'),
                Prod_img = $(this).find('.mid-content img'),
                Prod_title_2 = $(this).find('.right-content h3'),
                Prod_txt_2 = $(this).find('.right-content p'),
                tlProds = new TimelineMax({
                    paused: true, delay: 2
                });

            tlProds
                .to(Prod_title, 0.5, { y: 0, autoAlpha: 1 }, 0, "showProd")
                .to(Prod_txt, 0.5, { y: 0, autoAlpha: 1 }, 0.2, "showProd")
                .to(Prod_link, 0.5, { y: 0, autoAlpha: 1 }, 0.3, "showProd")
                .to(Prod_img, 0.5, { y: 0, autoAlpha: 1 }, 0.4, "showProd")
                .to(Prod_title_2, 0.5, { y: 0, autoAlpha: 1 }, 0.5, "showProd")
                .to(Prod_txt_2, 0.5, { y: 0, autoAlpha: 1 }, 0.6, "showProd")

            //if ($(this).isInViewport(offsetsection)) {
                tlProds.play();
            //}
        });

        //$('.prod_item').not(':first').hide(400, "swing");
        $('.product-tab-item').each(function () {
            $(this).click(function () {
                var tabId = $(this).attr('id');

                if (tabId == "all") {
                    $('.prod_item').each(function () {
                        $(this).show(400, "swing");
                    });
                } else {
                    $('.prod_item').not('#tab-' + tabId).hide(400, "swing");
                    $('#tab-' + tabId).show(400, "swing");
                }

            });

        });

        $(window).on('resize scroll', function () {
            $('.prod_item').each(function () {
                var offsetsection = $(this).attr('data-offset'),
                    Prod_title = $(this).find('h2'),
                    Prod_txt = $(this).find('.left-content p'),
                    Prod_link = $(this).find('.left-content a'),
                    Prod_img = $(this).find('.mid-content img'),
                    Prod_title_2 = $(this).find('.right-content h3'),
                    Prod_txt_2 = $(this).find('.right-content p'),
                    tlProdsList = new TimelineMax({
                        paused: true, delay: 0
                    });

                tlProdsList
                    .to(Prod_title, 1, { y: 0, autoAlpha: 1 }, 0, "showProd")
                    .to(Prod_txt, 1, { y: 0, autoAlpha: 1 }, 0.2, "showProd")
                    .to(Prod_link, 1, { y: 0, autoAlpha: 1 }, 0.3, "showProd")
                    .to(Prod_img, 1, { y: 0, autoAlpha: 1 }, 0.4, "showProd")
                    .to(Prod_title_2, 1, { y: 0, autoAlpha: 1 }, 0.5, "showProd")
                    .to(Prod_txt_2, 1, { y: 0, autoAlpha: 1 }, 0.6, "showProd")

                if ($(this).isInViewport(offsetsection)) {
                    tlProdsList.play();
                }
            });
        });

    }
}


// Don`t forget to export your renderer
export default ElectronicProductsRenderer;