import '../../../jquery-global.js';
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class ElectronicCustomerRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('ElectronicCustomerRenderer onDoneEnter');
        this.animateCustomer();
    }

    animateCustomer() {
        // Anime le menu
        var
            custom_title = $('.customer h1'),
            custom_txt = $('.customer .description'),
            custom_img = $('.customer img'),

            tlCustom = new TimelineMax({
                paused: true, delay: 0
            });

        tlCustom
            .to(custom_title, 1, { y: 0, autoAlpha: 1 }, 0.7, "initHome")
            .to(custom_txt, 1, { y: 0, autoAlpha: 1 }, 0.9, "initHome")
            .to(custom_img, 1, { y: 0, autoAlpha: 1 }, 1, "initHome")

        tlCustom.play();

        // if (location.href.indexOf('customer.html') !== -1) tlFooter.play();

    }

    // onLeave() { [...] }
    // onEnterCompleted() { [...] }
    // onLeaveCompleted() { [...] }



}


// Don`t forget to export your renderer
export default ElectronicCustomerRenderer;