import "../../../jquery-global.js";
import DefaultRenderer from "../default-renderer.js";
import { TimelineMax } from "gsap/all";

class PassiveNewsRenderer extends DefaultRenderer {
  // On enter on page
  onDoneEnter() {
    this.news = new Array();
    console.log("PassiveNewsRenderer onDoneEnter");
    this.animateNews();
    this.loadNews();

    var self = this;
    $("#load-more-news").click(function () {
      self.loadNews();
    });
  }

  animateNews() {
    var title_news = $(".first-news h1"),
      news_txt = $(".first-news p"),
      news_img = $(".first-news img"),
      link_news = $(".first-news a"),
      tlNews = new TimelineMax({
        paused: true,
        delay: 0.5,
      });

    tlNews
      .to(title_news, 1, { x: 0, autoAlpha: 1 }, 0.1, "initNews")
      .to(news_txt, 1, { x: 0, autoAlpha: 1 }, 0.3, "initNews")
      .to(news_img, 1, { x: 0, autoAlpha: 0.8 }, 0.4, "initNews")
      .to(link_news, 1, { x: 0, autoAlpha: 1 }, 0.8, "initNews");
    tlNews.play();

    var news_item = $(".news-item"),
      tlNewsItems = new TimelineMax({
        paused: true,
        delay: 0.5,
      });
    tlNewsItems.staggerTo(
      news_item,
      1,
      { y: 0, autoAlpha: 1 },
      0.2,
      "initNews-=0.5"
    );

    $("section.news").each(function () {
      var offsetsection = $(this).attr("data-offset");
      if ($(this).isInViewport(offsetsection) || 1) {
        tlNewsItems.play();
      }
    });

    $(window).on("resize scroll", function () {
      $("section.news").each(function () {
        var offsetsection = $(this).attr("data-offset");
        if ($(this).isInViewport(offsetsection) || 1) {
          tlNewsItems.play();
        }
      });
    });
  }

  loadNews() {
    var self = this;
    $.getJSON(
      "https://monroe-oe-solutoins.prezly.com/feed.json?culture=en&limit=10&target=self&year=&container=prezly-pressroom-container&date_format=&callback=?",
      function (data) {
        var html = "";

        var start = self.news.length;
        var loadedNews = data.items;

        console.log(start);
        console.log(data);
        console.log(loadedNews);
        console.log(loadedNews.length);

        if (start == 0) {
          var firstNews = loadedNews.shift();
          console.log(firstNews);
          if (firstNews["title"].length >= 80) {
            firstNews["title"] = firstNews["title"].substr(0, 80) + "...";
          }

          //html += '<img src="../img/header-news.jpg">';
          html += '<div class="first-news-content">';
          html += "<h1>" + firstNews["title"] + "</h1>";
          html +=
            "<p>" +
            firstNews["title"] +
            '</p><a href="' +
            firstNews["url"] +
            '" target="_blank">Read on<i class="fal fa-chevron-right"></i></a>';
          html += "</div>";

          $("#first-news").html(html);

          $("#first-news").css(
            "background",
            '#000 url("../img/header-news.jpg") center center no-repeat'
          );
          $("#first-news").css("background-size", "contain");

          start++;
          html = "";
        }

        console.log(loadedNews.length, loadedNews.length >= start, start);
        if (loadedNews.length >= start) {
          html = "";
          console.log(loadedNews.slice(start - 1, start + 3));
          $.each(loadedNews.slice(start - 1, start + 3), function (i, item) {
            if (item["title"].length >= 80) {
              item["title"] = item["title"].substr(0, 80) + "...";
            }

            self.news.push(item);

            html += '<div class="news-item">';
            html += '<img src="' + item["preview_image"] + '" alt="" />';
            html +=
              '<h2><a href="' +
              item["url"] +
              '" target="_blank">' +
              item["title"] +
              "</a></h2>";
            html += "</div>";
          });
        }

        $("#news-container").append(html);
        self.animateNews();
      }
    );
  }

  // onLeave() { [...] }
  // onEnterCompleted() { [...] }
  // onLeaveCompleted() { [...] }
}

// Don`t forget to export your renderer
export default PassiveNewsRenderer;
