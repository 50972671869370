import '../../../jquery-global.js';
import '../../../jquery.cycle.js';
import fancybox from '@fancyapps/fancybox';
import DefaultRenderer from '../default-renderer.js';
import { TimelineMax } from "gsap/all";

class ElectronicResearchRenderer extends DefaultRenderer {
    // On enter on page
    onDoneEnter() {
        console.log('ElectronicResearchRenderer onDoneEnter');
        this.animateResearch();
    }

    onDoneEnterCompleted() {
        
        this.initFancybox();
    }


    initFancybox() {
        $('document').ready(function(){
            $('.fancy').each(function(){
                $(this).attr('data-url', $(this).attr('href'));
                $(this).attr('href', '#');
            });
            $('.fancy').click(function(){
                $.fancybox.open({
                    src  : $(this).attr('data-url').replace(new RegExp('watch\\?v=', 'i'), 'embed/') + '?autoplay=1&amp;rel=0&amp;controls=1&amp;showinfo=0',
                    type : 'iframe'
                });
            });
        });
    }

    // Anim research
    animateResearch() {
        var
            logoresearch = $('.header-research .logo-header'),
            title_research = $('.header-research h1'),
            research_txt = $('.header-research p'),
            research_img = $('.header-research .img-header'),
            title_research_2 = $('.header-research h2'),
            item_research = $('.header-research .focus-item'),
            title_research_3 = $('.header-research h3'),
            video = ('.video-full-width'),

            tlVideo = new TimelineMax({
                paused: true, delay: 0
            });
        tlVideo
            .to(video, 1, { y: 0, autoAlpha: 1 }, 0, "initResearch")
        var
            tlResearch = new TimelineMax({
                paused: true, delay: 0.5
            });
        tlResearch
            .to(logoresearch, 1, { x: 0, autoAlpha: 1 }, 0.1, "initResearch")
            .to(title_research, 1, { x: 0, autoAlpha: 1 }, 0.3, "initResearch")
            .to(research_txt, 1, { x: 0, autoAlpha: 1 }, 0.4, "initResearch")
            .to(research_img, 1, { x: 0, autoAlpha: 1 }, 0.8, "initResearch")
            .to(title_research_2, 1, { x: 0, autoAlpha: 1 }, 1, "initResearch")
            .staggerTo(item_research, 1, { y: 0, autoAlpha: 1 }, 0.2, "initResearch-=1.2")
            .to(title_research_3, 1, { y: 0, autoAlpha: 1 }, 1.3, "initResearch")

        // $('#research .video-full-width').each(function () {
        //     var offsetsection = $(this).attr('data-offset');
        //     if ($(this).isInViewport(offsetsection)) {
        //         tlVideo.play();
        //     }
        // });

        // $(window).on('resize scroll', function () {
        //     $('#research .video-full-width').each(function () {
        //         var offsetsection = $(this).attr('data-offset');
        //         if ($(this).isInViewport(offsetsection)) {
        //             tlVideo.play();
        //         }
        //     });
        // });


        tlResearch.play();



        $('.research-content-item, .project-item').each(function () {
            
                var slide_item = $(this),
                tlSlideRight = new TimelineMax({
                    paused: true, delay: 0
                });

            tlSlideRight
                .to(slide_item, 1, { x: 0, autoAlpha: 1 }, 0.2, "showProd")

                if ($(this).isInViewport() || $(window).width() <= 1113) {
                tlSlideRight.play();
            }
        });

        $(window).on('resize scroll', function () {
            $('.research-content-item, .project-item').each(function () {
                    var slide_item = $(this),
                    tlSlideRightScroll = new TimelineMax({
                        paused: true, delay: 0
                    });

                tlSlideRightScroll
                    .to(slide_item, 1, { x: 0, autoAlpha: 1 }, 0.2, "showProd")

                    if ($(this).isInViewport() || $(window).width() <= 1113) {
                    tlSlideRightScroll.play();
                }
            });
        });
    }

    // onLeave() { [...] }
    // onEnterCompleted() { [...] }
    // onLeaveCompleted() { [...] }



}


// Don`t forget to export your renderer
export default ElectronicResearchRenderer;